/* global $, quickverleih, openPopup, closePopup, initAjaxDatepicker, initDataTableBlock */

quickverleih.startPage = (function () {

    function initVersionHistoryPopup() {

        if (quickverleih.config_params.showWhatsNewPopup) {
            $.ajax({
                url: 'includes/StartPage/ajax/ajax.php',
                type: 'POST',
                data: {
                    method: 'getWhatsNewContent'
                },
                success: function (data) {
                    var html = data;
        
                    openPopup(html, '1000px', false);
                }
            });
        }
    }
    
    const saveDoNotShowAgain = () => {
        $.ajax({
            url: 'includes/StartPage/ajax/ajax.php',
            type: 'POST',
            data: {
                method: 'saveDoNotShowAgainWhatsNew'
            },
            success: function () {
                quickverleih.config_params.showWhatsNewPopup = false;
                closePopup();
            }
        });
    }

    $(document).off('click', '.close-whats-new-popup-button').on('click', '.close-whats-new-popup-button', function() {
        let doNotShowAgain = $("input[type='checkbox'][name='do_not_show_again']").is(':checked') ? 1 : 0;
        // If the checkbox is selected save the data
        if (doNotShowAgain) {
            saveDoNotShowAgain();
        }
        closePopup();
    });

    return {
        initVersionHistoryPopup: initVersionHistoryPopup
    };
})();

$(document).ready(function () {

    const shouldInitStartPage = () => {
        var urlParams = new URLSearchParams(window.location.search);
        // if page is not set, load the start page also load if the param is set to '1-0_Dashboard'
        return !urlParams.has('page') || urlParams.get('page') === '1-0_Dashboard';
      }

    // if page is not set, load the start page
    if(!shouldInitStartPage()) {
        return;
    }

    var loadTable = function (table, id) {
		$.ajax({
			type: "POST",
            url: "includes/StartPage/views/" + table + ".php",
            success: function (html) {
                $("#" + id).html(html);
			}
		});
	};

	loadTable('EventsTable', 'events_table_container');
	loadTable('NotesTable', 'notes_table_container');

    function loadColorBars() {
        /*
         *	Calendar Articles popup
         */
        $(".colorBar").click(function () {

			$.ajax({
				url: 'includes/StartPage/ajax/ajax.php',
				type: 'POST',
				/* async: false,*/
				data: {
					id: $(this).attr('productId'),
					date: $(this).attr('date'),
					method: 'getProductContracts'
				},
				success: function (data) {
					openPopup(data, '1000px');
				}
			});

        });

        (function () {
            $('.colorBar').hover(function () {
                $(this).find('.tooltip').show();
                $(this).find('.tooltip').position({ at: 'bottom center', of: $(this), my: 'top' });
            });

            $('.colorBar').mouseleave(function () {
                $('.tooltip').hide();
            });
        })();
    }

    /*
    *	Notizen (Note) popup
    */
	$(document).on('click', '#new_note', function (e) {
		e.preventDefault();

        $.ajax({
            url: 'includes/StartPage/ajax/ajax.php',
            type: 'POST',
            /* async: false,*/
            data: {
                method: 'getNewNoteView'
            },
            success: function (data) {
                openPopup(data, '800px');
            }
        });

		return false;
    });

    $(document).on('click', '#save_note', function () {

        var id_note = $('#id_note').val();
        var note_subject = $('#note_subject').val();
        var note_content = $('#note_content').val();

        $.ajax({
            url: 'includes/StartPage/ajax/ajax.php',
            type: 'POST',
            /* async: false,*/
            data: {
                id_note: id_note,
                note_subject: note_subject,
                note_content: note_content,
                method: 'saveNote'
            },
            success: function (data) {
                // get data and repopulate table
                loadTable('NotesTable', 'notes_table_container');
                closePopup();
            }
        });

    });

	$(document).on('click', '.edit-note', function (e) {
		e.preventDefault();

        var id_note = $(this).parent().attr('id-note');

        $.ajax({
            url: 'includes/StartPage/ajax/ajax.php',
            type: 'POST',
            /* async: false,*/
            data: {
                id_note: id_note,
                method: 'editNote'
            },
            success: function (data) {
                openPopup(data, '800px');
            }
        });

		return false;
    });

    $(document).on('click', '.delete-note', function () {

        var id_note = $(this).parent().attr('id-note');
        var note_subject = $(this).parents('.startpage-note-container').find('.note-subject').html();

        var data = '<div class="dash-block-header"> <div class="message-header-title text-uppercase">';
        data += 'NOTIZ LÖSCHEN?';
        data += '</div></div>';
        data += '<div class="popup-content">';
        data += '<br><p><b>' + note_subject + '</b></p><br>';
        data += '<div class="button delete-button delete-note-yes" data-id="' + id_note + '">Löschen' /*+ qvapp.i18n.t('remove_article_from_contract_remove') */ + '</div>';
        data += '<a href="#close-modal" rel="modal:close"><div class="button white-button delete-note-no" style="float: right">Abbrechen' /*+ qvapp.i18n.t('remove_article_from_contract_cancel') */ + '</div></a>';

        openPopup(data, '500px');

    });

    $(document).on('click', '.delete-note-yes', function () {

        var id_note = $(this).attr('data-id');

        $.ajax({
            url: 'includes/StartPage/ajax/ajax.php',
            type: 'POST',
            /* async: false,*/
            data: {
                id_note: id_note,
                method: 'deleteNote'
            },
            success: function (data) {
                // get data and repopulate table
                loadTable('NotesTable', 'notes_table_container');

                var html = '<div class="dash-block-header"> <div class="message-header-title text-uppercase">';
                html += 'LÖSCHUNG ERFOLGREICH';
                html += '</div></div>';
                html += '<div class="popup-content">';
                html += '<br><p><b>' + data + '</b></p><br>';
                openPopup(html, '500px');
            }
        });

    });


    /*
    *	Termine (Event) popup
    */

	$(document).on('click', '#new_event', function (e) {
		e.preventDefault();

        $.ajax({
            url: 'includes/StartPage/ajax/ajax.php',
            type: 'POST',
            /* async: false,*/
            data: {
                method: 'getNewEventView'
            },
            success: function (data) {
                openPopup(data, '800px');
                // Andy: merge check done - commented for now 
				// - error for initAjaxDatepicker not found 
				// - works ok while commented
                /* initialize datepickers */
                // initAjaxDatepicker('#event_start', '#calendar_event_start');
                // initAjaxDatepicker('#event_end', '#calendar_event_end');
            }
        });

		return false;
    });

	const getPickerValue = (pickerId) => {
        let pickerVal, 
            time = "00:00";

        const $pickerOutput = $(pickerId).siblings(".datetimepicker-output");
        if($pickerOutput.length > 0 && (pickerVal = $pickerOutput.val()) !== '') {
            time = pickerVal.slice(-5);
        }	

        return {
            dayTimestamp: $(pickerId).pickadate('picker').get('select').pick,
            time: time
        };
    }

    $(document).on('click', '#save_event', function () {

        var id_event = $('#id_event').val();
        var event_name = $('#event_name').val();
        var event_art = $('#event_art').val();
		
        // Andy: merge check done - saves event correctly
		const event_start_value = getPickerValue('#eventStart_0');
		const fromDateTime = moment(event_start_value.dayTimestamp);
        const event_start = fromDateTime.format('YYYY-MM-DD') + "T" + event_start_value.time;

		const event_end_value = getPickerValue('#eventEnd_0');
		const toDateTime = moment(event_end_value.dayTimestamp);
		const event_end = toDateTime.format('YYYY-MM-DD') + "T" + event_end_value.time;


        var event_description = $('#event_description').val();
        var event_admins = [];

		$('#eventEnd_0').pickadate('picker').get('select').pick

        $.each($('.event_admins'), function (i, e) {
            if ($(this).is(":checked")) {
                event_admins.push($(this).val());
            }
        });

        $.ajax({
            url: 'includes/StartPage/ajax/ajax.php',
            type: 'POST',
            data: {
                id_event: id_event,
                event_name: event_name,
                event_art: event_art,
                event_start: event_start,
                event_end: event_end,
                event_description: event_description,
                event_admins: event_admins,
                method: 'saveEvent'
            },
            success: function (data) {
                // get data and repopulate table
                loadTable('EventsTable', 'events_table_container');
                closePopup();
            }
        });

    });

	$(document).on('click', '.edit-event', function (e) {

		e.preventDefault();

        var id_event = $(this).parent().attr('id-event');

        $.ajax({
            url: 'includes/StartPage/ajax/ajax.php',
            type: 'POST',
            /* async: false,*/
            data: {
                id_event: id_event,
                method: 'editEvent'
            },
            success: function (data) {
                openPopup(data, '800px');
                // Andy: merge check done - commented for now 
				// - error for initAjaxDatepicker not found 
				// - works ok while commented
                /* initialize datepickers */
                // initAjaxDatepicker('#event_start', '#calendar_event_start');
                // initAjaxDatepicker('#event_end', '#calendar_event_end');
            }
        });


		return false;
    });

    $(document).on('click', '.delete-event', function () {

        var id_event = $(this).parent().attr('id-event');
        var event_name = $(this).parents('.startpage-event-container').find('.event-name').html();

        var data = '<div class="dash-block-header"> <div class="message-header-title text-uppercase">';
        data += 'Termin Löschen?';
        data += '</div></div>';
        data += '<div class="popup-content">';
        data += '<br><p><b>' + event_name + '</b></p><br>';
        data += '<div class="button delete-button delete-event-yes" data-id="' + id_event + '">Löschen' /*+ qvapp.i18n.t('remove_article_from_contract_remove') */ + '</div>';
        data += '<a href="#close-modal" rel="modal:close"><div class="button white-button delete-event-no" style="float: right">Abbrechen' /*+ qvapp.i18n.t('remove_article_from_contract_cancel') */ + '</div></a>';

        openPopup(data, '500px');

    });

    $(document).on('click', '.delete-event-yes', function () {

        var id_event = $(this).attr('data-id');

        $.ajax({
            url: 'includes/StartPage/ajax/ajax.php',
            type: 'POST',
            /* async: false,*/
            data: {
                id_event: id_event,
                method: 'deleteEvent'
            },
            success: function (data) {
                // get data and repopulate table
                loadTable('EventsTable', 'events_table_container');

                var html = '<div class="dash-block-header"> <div class="message-header-title text-uppercase">';
                html += 'Termin Gelöscht';
                html += '</div></div>';
                html += '<div class="popup-content">';
                html += '<br><p><b>' + data + '</b></p><br>';
                openPopup(html, '500px');
            }
        });

    });

    function initCurrentLoans() {

        var tableLoans = $('table#current_loans'); // .DataTable();
        var tableReturns = $('table#incoming_returns'); // .DataTable();

        tableLoans.on('draw.dt', function (e) {
            // console.log('init 1!');

            tableLoans.off('draw.dt');

            let elem = $(this);
            var table = elem.DataTable();

            // Retrieve saved sorting order from session storage
            const institutionsEnabled = tableLoans.data('institutions-enabled') == true;
            const savedOrder = sessionStorage.getItem('tableLoansOrder');

            const defaultOrder = institutionsEnabled ? [[3, 'asc']] : [[2, 'asc']];
            const order = savedOrder ? JSON.parse(savedOrder) : defaultOrder;

            // Apply the saved or default sorting order
            table.order(order).draw();

            // Save the sorting order to session storage when it changes
            table.on('order.dt', function () {
                const order = table.order();
                sessionStorage.setItem('tableLoansOrder', JSON.stringify(order));
            });

			$(this).closest('#current_loans_wrapper input').ready(function () {
				let $contractTypeDropdown = $('.select_contract_type');
				let searchInput = $(this).find('#current_loans_wrapper input');

				let reloadContracts = () => {
					let init = true;
					let searchTerm = searchInput.val();
					let selectedContractType = $contractTypeDropdown.val();
					
					$('#load_wrap_id').addClass('loading');
					$.ajax({
						type: "GET",
						url: "includes/StartPage/views/loans_table/CurrentLoansTable.php?" + "contract_type=" + selectedContractType + "&article_loan_search=" + searchTerm + "&contract_init=" + init,
						success: function (data) {

							let table = $('#current_loans').DataTable();

							table.clear();

							$(data).each(function () {

								if ($(this).is('tr')) {

									table.row.add($(this));
								}
							});
							
							table.draw();

							$contractTypeDropdown.val(selectedContractType);
							searchInput.val(searchTerm);

							setTimeout(function () {
								$('#load_wrap_id').removeClass('loading');
							}, 200);

						}
					});
				}

				$(this).find('#current_loans_wrapper input').off().on('keypress', function (e) {
					let key = e.which || e.keyCode;
					if (key === 13) {
						reloadContracts();
					}
				});

				$contractTypeDropdown.off('change').on('change', () => {
					reloadContracts();
				});

				reloadContracts();
			});
		});

        tableReturns.on('draw.dt', function (e) {

            tableReturns.off('draw.dt');

            var elem = $(this);
            var table = elem.DataTable();

            // Retrieve saved sorting order from session storage
            const institutionsEnabled = tableReturns.data('institutions-enabled') == true;
            const savedOrder = sessionStorage.getItem('tableReturnsOrder');

            const defaultOrder = institutionsEnabled ? [[3, 'asc']] : [[2, 'asc']];
            const order = savedOrder ? JSON.parse(savedOrder) : defaultOrder;

            // Apply the saved or default sorting order
            table.order(order).draw();

            // Save the sorting order to session storage when it changes
            table.on('order.dt', function () {
                const order = table.order();
                sessionStorage.setItem('tableReturnsOrder', JSON.stringify(order));
            });

			$(this).closest('#incoming_returns_wrapper input').ready(function () {
				let $returnStatusDropdown = $('.select_return_status');
				let searchInputReturns = $(this).find('#incoming_returns_wrapper input');

				let reloadReturns = () => {
					let init = true;
					let searchTerm = searchInputReturns.val();
					let selectedReturnStatus = $returnStatusDropdown.val();
					
					$('#ret_wrap_id').addClass('loading');
					$.ajax({
						type: "GET",
						url: "includes/StartPage/views/returns_table/IncomingReturnsTable.php?" + "return_status=" + selectedReturnStatus + "&article_ret_search=" + searchTerm + "&contract_init=" + init,
						success: function (data) {

							let table = $('#incoming_returns').DataTable();

							table.clear();

							$(data).each(function () {

								if ($(this).is('tr')) {

									table.row.add($(this));
								}
							});

							table.draw();
							$returnStatusDropdown.val(selectedReturnStatus);
							searchInputReturns.val(searchTerm);

							setTimeout(function () {
								$('#ret_wrap_id').removeClass('loading');
							}, 200);
						}
					});
				};

				$(this).find('#incoming_returns_wrapper input').off().on('keypress', function (e) {
					let key = e.which || e.keyCode;
					if (key === 13) {
						reloadReturns();
					}
				});

				$returnStatusDropdown.off('change').on('change', () => {
					reloadReturns();
				})

				reloadReturns();
			});
		});
	}

    function initArtCalendar() {
        loadColorBars();

        var articleCalendar = $('#article_calendar');
        /**
         * for this custom event, check main.js
         */
        $(document).off('extra_filters_finished').on('extra_filters_finished', function () {

            // var month  = filter.find('#table-stats-month-data');
            let month = $('#table-stats-month-data');
            // var year   = filter.find('#table-stats-year-data');
            let year = $('#table-stats-year-data');

            let $search_input = $('.data-table-block-performance-limit').find('.dataTables_filter').find('input');

            let $categoriesDropdown = $('.select_category');

            $search_input.on('keypress', function (e) {
                let key = e.which || e.keyCode;
                if (key === 13) {
                    _updateTable();
                }

            });

            var _updateTable = function (e) {

                articleCalendar.addClass('loading');

                // generate the list with selected categories ids
                let selected_categories_ids = [];
                $categoriesDropdown.each(function() {
                    selected_categories_ids.push($(this).val())
                })

                let search_text = $search_input.val();
                $.ajax({
                    type: "POST",
                    url: "includes/StartPage/views/article_calendar/articles_overview.php?",
                    data: {
                        article_calendar_year: year.val(),
                        article_calendar_month: month.val(),
                        article_search_for: search_text,
                        selected_categories_ids
                    },
                    success: function (html) {

                        articleCalendar.html(html);
                        initArtCalendar();

                        articleCalendar.find('.data-table-block').each(initDataTableBlock);

                        articleCalendar.find('.dataTables_filter input').val(search_text);

                        articleCalendar.removeClass('loading');
                    }
                });
            };

            month.off('change').on('change', _updateTable);
            year.off('change').on('change', _updateTable);
            $categoriesDropdown.off('change').on('change', _updateTable);
        });
    }

    (function init() {
        initCurrentLoans();
        initArtCalendar();
    })();
});
